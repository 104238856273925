import React from 'react';
import { Line, defaults } from 'react-chartjs-2';
import { css } from 'emotion';
import { formatCurrency } from './calculateValuation';
import './legend.css';
import { useLocation, useNavigate } from '@reach/router';
import qs from 'query-string';

defaults.global.defaultFontColor = 'rgb(120, 119, 119)';

const options = {
  title: {
    display: true,
    text: 'Historical Valuation Chart',
    padding: 36,
    fontSize: 16,
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
  },
  tooltips: {
    enabled: true,
    callbacks: {
      title: function (tooltipItem, data) {
        return '';
      },
      label: function (tooltipItem, data) {
        const dollars = data['datasets'][0]['data'][tooltipItem['index']];
        return formatCurrency(dollars, '$');
      },
      afterLabel: function (tooltipItem, data) {
        return '';
      },
    },
    xPadding: 12,
    yPadding: 12,
    bodyFontSize: 14,
    displayColors: false,
  },
  scales: {
    xAxes: [
      {
        barPercentage: 1.0,
        categoryPercentage: 0.5,
        ticks: {
          fontFamily: 'Avenir Next',
          padding: 12,
          fontSize: 14,
          color: 'rgb(120, 119, 119)',
          // beginAtZero: true,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontFamily: 'Avenir Next',
          fontSize: 14,
          beginAtZero: true,
          stepSize: 2000000,
          suggestedMax: 3000000,
          color: 'rgb(206, 206, 206)',
          padding: 16,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            return formatCurrency(label, '$2');
          },
        },
        gridLines: {
          borderDash: [4, 4],
          color: 'rgb(206, 206, 206)',
          drawBorder: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  legendCallback: function (chart) {
    const label = chart.data.datasets[0].label;
    return `<div class="legend-wrapper"><div class="circle"></div><div class="label">${label}</div>`;
  },
};

class LineChart extends React.Component {
  state = {
    legend: '<span>Legend</span>',
  };

  componentDidMount() {
    const legend = this.generateLegend();
    this.setState({ legend });
  }

  generateLegend() {
    if (!this.chart) return null;
    return this.chart.chartInstance.generateLegend();
  }

  createMarkup() {
    return { __html: this.state.legend };
  }

  render() {
    const { dataSrc } = this.props;
    const labels = dataSrc.map((item) => item.year);
    const data = dataSrc.map((item) => item.valuation);

    const dataLineChart = {
      labels,
      datasets: [
        {
          label: 'Company Valuation',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(225, 63, 94,0.4)',
          borderColor: 'rgba(225, 63, 94,1)',
          borderDash: [],
          pointBackgroundColor: 'rgb(225, 63, 94)',
          pointRadius: 4,
          pointHitRadius: 10,
          data,
        },
      ],
    };

    return (
      <>
        <Line
          data={dataLineChart}
          options={options}
          ref={(chart) => {
            this.chart = chart;
          }}
        />
        <div
          css={css`
            ${tw`text-left flex justify-between mt-6`}
          `}
        >
          <div dangerouslySetInnerHTML={this.createMarkup()} />
          <div
            css={css`
              ${tw`text-grey-dark`}
            `}
          >
            US Dollar
          </div>
        </div>
      </>
    );
  }
}

export default LineChart;

export const Board = ({ dataSrc }) => {
  const { search } = useLocation();
  const nagigate = useNavigate();
  const { year } = qs.parse(search);
  const yearParam = parseInt(year);

  if (Array.isArray(dataSrc)) {
    return (
      <div
        css={css`
          ${tw`rounded`}
          border: 1px solid #f3f3f5;
        `}
      >
        <div
          css={css`
            ${tw`flex justify-between text-grey-dark uppercase py-4 px-6`}
            border-bottom: 1px solid #f3f3f5;
          `}
        >
          <div>Year</div>
          <div>Valuation (US Dollar)</div>
        </div>
        {[...dataSrc].reverse().map(({ year, valuation }, index) => {
          const isCurrent = yearParam ? yearParam === year : index === 0;

          return (
            <div
              key={index}
              css={css`
                ${tw`mx-6`}
                border-bottom: 1px solid #f3f3f5;
              `}
              onClick={
                isCurrent
                  ? undefined
                  : () =>
                      nagigate(`/admin/valuation?year=${year}`, {
                        replace: true,
                      })
              }
            >
              <div
                css={css`
                  ${tw`flex justify-between text-black -mx-6 px-6 py-3`}
                  ${isCurrent ? tw`font-semibold` : tw`cursor-pointer`}
                `}
              >
                <div>{year}</div>
                <div>
                  {Number(valuation).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                    currency: 'USD',
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};
