import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SortArrow from './SortArrow';
import Pagination from './Pagination';
import { css } from 'emotion';

const defaultColumnDivisionClass = `
  > div:nth-child(1) {
    width: 15%;
  }

  > div:nth-child(2) {
    width: 20%;
  }

  > div:nth-child(3) {
    width: 27.5%;
  }

  > div:nth-child(4) {
    width: 27.5%;
  }

  > div:nth-child(5) {
    width: 10%;
  }
`;

const defaultRowStyle = `
  display: flex;
  align-items: center;
`;

const defaultHeaderStyle = `
  display: flex;
  align-items: center;
`;

class FlexTable extends React.Component {
  constructor(props) {
    super(props);
    const { data, numPerPage } = props;
    const maxPages = Math.ceil(data.length / numPerPage);

    this.state = {
      data: this.paginate(1, data),
      sortTo: null,
      sortUp: false,
      maxPages,
      currentPage: 1,
      sortedData: data,
    };
  }

  sort = ({ comparable, pointTo }) => {
    if (typeof comparable !== 'function') {
      comparable = (a, b) => {
        const aL = a[pointTo].toLowerCase();
        const bL = b[pointTo].toLowerCase();
        if (aL < bL)
          // sort string ascending
          return -1;
        if (aL > bL) return 1;
        return 0;
      };
    }

    this.setState((prevState) => {
      const { sortTo, sortUp } = prevState;
      if (sortTo === pointTo) {
        const sortedData = [...this.props.data].sort(comparable);
        if (sortUp) {
          sortedData.reverse();
        }
        return { sortedData, sortUp: !sortUp };
      } else {
        const sortedData = [...this.props.data].sort(comparable);
        return { sortedData, sortUp: true, sortTo: pointTo };
      }
    });
  };

  paginate = (pageNumber, data) => {
    const { numPerPage } = this.props;
    const start = (pageNumber - 1) * numPerPage;
    const end = start + numPerPage;
    return data.slice(start, end);
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      nextState.sortedData !== this.state.sortedData ||
      nextState.currentPage !== this.state.currentPage
    ) {
      this.setState({
        data: this.paginate(nextState.currentPage, nextState.sortedData),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      const { data, numPerPage } = nextProps;
      const maxPages = Math.ceil(data.length / numPerPage);

      this.setState({
        data: this.paginate(1, data),
        sortTo: null,
        sortUp: false,
        maxPages,
        currentPage: 1,
        sortedData: data,
      });
    }
  }

  render() {
    const {
      viewModel,
      headerStyle,
      columnDivisionClass,
      className,
      rowStyle,
      rowWrapTemplate: RowWrapTemplate,
    } = this.props;

    // Construct ROW template to render table
    const Row = (row) => (
      <RowWrapTemplate
        {...row}
        className={css`
          ${rowStyle + ' ' + columnDivisionClass}
        `}
      >
        {viewModel.map(({ columnComponent: Component }, index) => (
          <div className="pr-6" key={index}>
            {<Component {...row} />}
          </div>
        ))}
      </RowWrapTemplate>
    );

    return (
      <React.Fragment>
        <div
          css={css`
            ${tw`bg-white border border-grey-lighter overflow-hidden rounded`};
          `}
          className={classnames({ [className]: className })}
        >
          <div
            css={css`
              ${tw`bg-white border-b border-grey-lighter px-6 py-3 text-sm text-grey-dark uppercase`}
              ${headerStyle + ' ' + columnDivisionClass}
            `}
            customStyle={headerStyle + ' ' + columnDivisionClass}
          >
            {viewModel.map(({ title, sorting, pointTo, comparable }) => (
              <div
                key={title}
                css={css`
                  ${tw`pr-6 flex items-center font-medium`}
                `}
              >
                {title}
                {sorting && (
                  <SortArrow
                    onClick={() => this.sort({ pointTo, comparable })}
                    active={this.state.sortTo === pointTo}
                    up={this.state.sortUp}
                  />
                )}
              </div>
            ))}
          </div>
          {this.state.data.length ? (
            this.state.data.map((row, index) => <Row key={index} {...row} />)
          ) : (
            <div
              css={css`
                ${tw`py-4 px-6`}
              `}
            >
              No data available
            </div>
          )}
        </div>

        {this.state.maxPages > 1 && (
          <div
            css={css`
              ${tw`mt-5`}
            `}
          >
            <Pagination
              max={this.state.maxPages}
              current={this.state.currentPage}
              onChange={this.setCurrentPage}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const DefaultRowWrapTemplate = ({ children, ...props }) => (
  <div
    css={css`
      ${tw`px-6`}
    `}
  >
    <div
      css={css`
        ${tw`border-b border-grey-lighter py-3`};
      `}
      {...props}
    >
      {children}
    </div>
  </div>
);

FlexTable.defaultProps = {
  numPerPage: 10,
  columnDivisionClass: defaultColumnDivisionClass,
  headerStyle: defaultHeaderStyle,
  rowStyle: defaultRowStyle,
  rowWrapTemplate: DefaultRowWrapTemplate,
};

FlexTable.propTypes = {
  data: PropTypes.array.isRequired,
  rowStyle: PropTypes.string,
  headerStyle: PropTypes.string,
  viewModel: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  columnDivisionClass: PropTypes.string,
  numPerPage: PropTypes.number,
  rowWrapTemplate: PropTypes.any,
};

export default FlexTable;
