import React, { useState } from 'react';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaGithubAlt,
} from 'react-icons/fa';
import { ReactComponent as SvgLogoDefault } from '@/components/svg/logo-default.svg';
import { ReactComponent as SvgDots } from '@/components/svg/login-dots-square.svg';
import { Button } from '@/shared/styled';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useAuthContext } from '@/context/AuthContext';
import FormReissue from './FormReissue';
import { css } from 'emotion';
import './style.css';

const socialIcons = [
  {
    href: 'https://www.facebook.com/dwarvesf/',
    title: 'Facebook',
    Icon: FaFacebookF,
  },
  {
    href: 'https://linkedin.com/company/dwarvesf',
    title: 'Linkedin',
    Icon: FaLinkedinIn,
  },
  {
    href: 'https://twitter.com/dwarvesf',
    title: 'Twitter',
    Icon: FaTwitter,
  },
  {
    href: 'https://github.com/dwarvesf',
    title: 'Github',
    Icon: FaGithubAlt,
  },
];

const LoginWrapper = (props) => {
  const { setErrorDialog } = props;
  const [showReissue, setShowReissue] = useState(false);

  const toggleReissue = () => {
    setShowReissue((v) => !v);
  };

  return (
    <div className="login-container min-h-screen flex flex-col">
      <div className="mt-10 ml-16 flex inline-block items-center">
        <SvgLogoDefault className="inline-block mr-4 mb-1" />
        <h1 className="inline-block text-black lg:text-lg text-base font-bold">
          DWARVES <br /> INVESTMENT
        </h1>
      </div>

      <div
        className={['container px-4']}
        style={{ marginTop: 100, marginLeft: 84 }}
      >
        <div className="relative mt-10 ml-10">
          <SvgDots className={['absolute']} style={{ top: -40, left: -40 }} />
          <div
            css={css`
              // z-index: 1;
              min-height: ${showReissue ? 520 : 410}px;
              width: 360px;
              padding: 40px;
              padding-bottom: 24px;
              ${tw`relative bg-white rounded-lg shadow-md`}
            `}
            showReissue={showReissue}
          >
            <h1 className="text-center font-bold text-black text-4xl mb-10">
              Welcome
            </h1>

            {!showReissue ? (
              <Login {...props} openReissue={toggleReissue} />
            ) : (
              <FormReissue
                goBackLogin={toggleReissue}
                setErrorDialog={setErrorDialog}
              />
            )}
            <div className={showReissue ? 'mt-6' : 'mt-12'}>
              <div>
                {socialIcons.map(({ href, Icon, title }) => (
                  <li className={['inline-block mr-6']} key={title}>
                    <a
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={title}
                      className="text-gray-500 hover:text-primary"
                    >
                      <Icon className="inline-block text-xl" />
                    </a>
                  </li>
                ))}
              </div>
              <div
                className={['text-sm w-full mt-4']}
                style={{ color: '#787777' }}
              >
                A product of Dwarves Foundation Inc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;

const Login = ({ login, loading, openReissue }) => {
  const { isWeb3Login, web3Login } = useAuthContext();

  return (
    <div>
      <div className="mt-16">
        <div
          disabled={isWeb3Login}
          css={css`
            ${tw`flex mb-4 items-start`}
            pointer-events: ${isWeb3Login ? 'none' : 'auto'};
          `}
        >
          <span className="mt-1">
            <span
              css={css`
                ${tw`w-5 h-5 mr-3 flex items-center justify-center rounded-full text-xs`};
                ${isWeb3Login
                  ? tw`border border-green text-green`
                  : tw`border border-red text-red`}
              `}
            >
              {isWeb3Login ? <FaCheck /> : <FaTimes />}
            </span>
          </span>
          <p>
            <span
              role="button"
              css={css`
                ${tw`text-primary cursor-pointer underline`}
              `}
              onClick={web3Login}
            >
              Sign in
            </span>{' '}
            to your Metamask account and click here to enable access
          </p>
        </div>
      </div>

      <div
        css={css`
          ${tw`flex justify-between mt-8`}
        `}
      >
        <Button
          type="primary"
          onClick={login}
          disabled={!isWeb3Login || loading}
        >
          Login
        </Button>
        <Button onClick={openReissue}>Reissue</Button>
      </div>
      <div></div>
    </div>
  );
};
