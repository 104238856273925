export const ROUTES = {
  AUTH: '/auth',
  LOGIN: '/login',
  PUBLIC_REISSUE: '/reissue',
  REGISTER: '/register',
  TRANSACTION: '/transactions',
  DASHBOARD: '/',
  MEMBERS: '/admin/members',
  INVITE_MEMBER: '/admin/invite',
  REISSUES: '/admin/reissues',
  REISSUE: '/admin/reissues/:id',
  VALUATION: '/admin/valuation',
  NO_AUTHEN: '/403',
  SERVER_ERROR: '/500',
};
