import React, { useEffect } from 'react';
import useSetState from '@/utils/useSetState';
import dayjs from 'dayjs';
import { Heading } from '@/shared/styled';
import FlexTable from '@/components/FlexTable';
import { Redirect } from '@reach/router';
import request from '@/utils/request';
import LoadSwitcher from '@/components/LoadSwitcher';
import ClipboardPane from '@/components/ClipboardPane';
import { css } from 'emotion';

const FromColumn = ({ from }) => (
  <div
    css={css`
      max-width: 280px;
    `}
  >
    {from ? <ClipboardPane maxLength={20} content={from} /> : '---'}
  </div>
);
const ToColumn = ({ to }) => (
  <div
    css={css`
      max-width: 280px;
    `}
  >
    {to ? <ClipboardPane maxLength={20} content={to} /> : '---'}
  </div>
);
const AmountColumn = ({ value }) => (
  <span
    css={css`
      margin-left: 5px;
      font-weight: 500;
      border-bottom: 2px solid #ddd;
    `}
  >
    {parseInt(value).toLocaleString({ style: 'currency' }) || '---'}
  </span>
);
const DateColumn = ({ timeStamp }) => (
  <span>{dayjs(timeStamp * 1000).format('DD/MM/YYYY') || '---'}</span>
);

const viewModel = [
  {
    title: 'From',
    pointTo: 'from',
    columnComponent: FromColumn,
  },
  {
    title: 'To',
    pointTo: 'to',
    columnComponent: ToColumn,
  },
  {
    title: 'Date',
    pointTo: 'timeStamp',
    columnComponent: DateColumn,
    sorting: true,
    comparable: (a, b) => b.timeStamp - a.timeStamp,
  },
  {
    title: 'Amount',
    pointTo: 'value',
    columnComponent: AmountColumn,
    sorting: true,
    comparable: (a, b) => b.value - a.value,
  },
];

export default function Transaction() {
  const [state, setState] = useSetState({
    fetching: true,
    data: [],
    fetchError: false,
  });

  useEffect(() => {
    request
      .get('/transactions')
      .then((data) => {
        setState({ fetching: false, data: data });
      })
      .catch((err) => {
        setState({ fetchError: true });
      });
  }, [setState]);

  if (state.fetchError) {
    return <Redirect to="/404" noThrow />;
  }

  return (
    <LoadSwitcher isLoading={state.fetching}>
      <Heading>Transaction</Heading>
      <FlexTable
        data={state.data}
        viewModel={viewModel}
        columnDivisionClass={`
        >div:nth-of-type(1) { width: 30% };
          >div:nth-of-type(2) { width: 35% };
          >div:nth-of-type(3) { width: 20% };
          >div:nth-of-type(4) { width: 15% };
            `}
      />
    </LoadSwitcher>
  );
}
