export const COMPANY_VALUATION = 'company_valuation';

export default (data, key, year) => {
  switch (key) {
    case 'assets': {
      const {
        details: { cash, stuff },
        total,
      } = data.assets;
      return {
        TOTAL: total,
        'Stuff: Servers, laptops, printers, furniture, cars, etc.': stuff,
        Cash: cash,
      };
    }
    case 'taxable_assets': {
      const { account_receivable = 0, future_owner_profits = [] } = data;

      const sumProfits = sumFutureOwnerProfits(future_owner_profits);
      const TOTAL = sumProfits + account_receivable;
      return {
        TOTAL: Number(TOTAL),
        'Future owner profits': Number(sumProfits),
        'Account receivable': Number(account_receivable),
      };
    }
    case 'future_profits': {
      const {
        future_owner_profits = [],
        net_profit = 0,
        employee_profit_share = 0,
        information = [],
      } = data;
      let retained_earning = 0;

      if (information.length > 0) {
        const [yearInfo] = information.filter(
          (item) => `${item.year}` === year,
        );
        retained_earning = yearInfo
          ? yearInfo.retained_earning
          : information[information.length - 1].retained_earning;
      }

      const sumProfits = sumFutureOwnerProfits(future_owner_profits);
      const remainder_of_profits = net_profit - employee_profit_share;

      return {
        'NPV OF FUTURE PROFIT': Number(sumProfits),
        'Net profit': net_profit,
        'Employee profit share': employee_profit_share,
        'Remainder of profits': remainder_of_profits,
        'Retained earnings (rainy day)': retained_earning,
        'Owner profit to distribute': remainder_of_profits - retained_earning,
        'Future owner profits, by year': future_owner_profits,
      };
    }
    case 'valuation_pamameters': {
      const {
        revenue,
        avg_net_margin,
        avg_rev_growth_rate,
        fraction,
        discount_rate,
      } = data;
      return {
        'Current annual revenue': formatCurrency(revenue, '$'),
        'Avg Net margin': formatCurrency(avg_net_margin, '%'),
        'Avg Rev growth rate': formatCurrency(avg_rev_growth_rate, '%'),
        'Employee PS fraction': formatCurrency(fraction.employee_ps, '%'),
        'Discount rate': formatCurrency(discount_rate, '%'),
        'Tax rate': formatCurrency(0, '%'),
      };
    }
    case 'historical_net_margin': {
      const { information = [] } = data;
      const obj = {};
      information.forEach((info) => {
        const { year, net_margin } = info;
        obj[year] = net_margin;
      });
      return obj;
    }
    case 'liabilities': {
      const {
        details,
        total,
      } = data.liabilities;
      return {
        ...details,
        TOTAL: total,
      };
    }
    case COMPANY_VALUATION: {
      const { information = [] } = data;
      if (!Array.isArray(information) || information.length === 0) {
        return [{ year: 2014, valuation: 0 }];
      }
      const valuations = information.map(({ year, company_value }) => ({
        year,
        valuation: company_value,
      }));
      return valuations;
    }
    default: {
      return null;
    }
  }
};

const sumFutureOwnerProfits = function (arr) {
  return arr.reduce((total, num) => total + num, 0);
};

export const formatCurrency = function (data, currency) {
  switch (currency) {
    case '$': {
      return Number(data).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    }
    case '%': {
      return `${Number(data * 100).toFixed(2)}%`;
    }
    case '$2': {
      return Number(data).toLocaleString('en-US', {
        currency: 'USD',
      });
    }
    default: {
      return data;
    }
  }
};
