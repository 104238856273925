import React from 'react';
import Collapsible from 'react-collapsible';
import { TiArrowSortedUp } from 'react-icons/ti';
import calculateValuation, { formatCurrency } from './calculateValuation';
import { css } from 'emotion';
import Line, { Board } from './CompanyValueLineChart';

const CollapsingTrigger = ({ title }) => {
  return (
    <div className="flex">
      <div className="arrow-up">
        <TiArrowSortedUp style={{ fontSize: '1.125rem' }} />
      </div>
      <p className="collapsing__text pl-1">{title || 'Title'}</p>
    </div>
  );
};

const CardCollapsible = ({ open = true, sectionTitle, children }) => {
  return (
    <Collapsible
      trigger={<CollapsingTrigger title={sectionTitle} />}
      open={open}
      className="collapsing"
      triggerClassName="collapsing__trigger"
      openedClassName="collapsing collapsing--is-open"
      triggerOpenedClassName="collapsing__trigger collapsing__trigger--is-open"
      transitionTime={70}
      contentOuterClassName="collapsing__outer"
      contentInnerClassName="collapsing__inner"
    >
      {children}
    </Collapsible>
  );
};

export default CardCollapsible;

export const CardItem = ({ data, name, currency = '$', year }) => {
  const valuationData = calculateValuation(data, name, year);
  // If year is null, this is the current year
  const currentYear = year ? Number(year) + 1 : new Date().getFullYear();

  return (
    valuationData &&
    Object.keys(valuationData).map((key, index) => {
      if (Array.isArray(valuationData[key])) {
        return (
          <div key={index}>
            <div
              css={css`
                ${[tw`py-4`]}
              `}
            >
              {key}
            </div>
            {valuationData[key].map((data, index) => (
              <div
                key={index}
                css={css`
                  border-top: 1px solid #f3f3f5;
                  ${tw`flex justify-between py-4 pl-10`}
                `}
              >
                <div
                  css={css`
                    ${tw`italic`}
                  `}
                >
                  Year {currentYear + index}
                </div>
                <div>{formatCurrency(data, currency)}</div>
              </div>
            ))}
          </div>
        );
      }
      return (
        <div
          key={index}
          css={css`
            ${[
              tw`flex justify-between py-4`,
              key === 'NPV OF FUTURE PROFIT'
                ? tw`bg-grey-lighter -mx-2 px-2`
                : '',
            ]}
          `}
        >
          <p>{key}</p>
          <p>{formatCurrency(valuationData[key], currency)}</p>
        </div>
      );
    })
  );
};

export const CardCompanyValue = ({ data, year }) => {
  const COMPANY_VALUATION = 'company_valuation';
  const valuationData = calculateValuation(data, COMPANY_VALUATION, year);

  return (
    <div
      className={css`
        ${tw`w-full bg-white flex justify-between p-8`}
      `}
    >
      <div
        css={css`
          flex: 3;
        `}
      >
        <Line dataSrc={valuationData} />
      </div>
      <div
        css={css`
          flex: 2;

          ${tw`pt-10 pl-20`}
        `}
      >
        <Board dataSrc={valuationData} />
      </div>
    </div>
  );
};
