import React from 'react';
import PropTypes from 'prop-types';
import smartTruncate from 'smart-truncate';
import CopyToClipboard from '@uxui/copy-to-clipboard-react';
import { MdContentCopy } from 'react-icons/md';
import { css } from 'emotion';

const ClipboardPane = React.memo(({ content, maxLength }) => {
  return (
    <CopyToClipboard>
      {({ copy, copied, turnOffCopied }) => {
        const handleCopy = (e) => {
          if (content && !copied) {
            copy(content);
            setTimeout(() => {
              turnOffCopied();
            }, 1000);
          }
        };

        const copyOnClick = (e) => {
          e.preventDefault();
          handleCopy();
          e.stopPropagation();
        };

        const copyOnKeyPress = (e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleCopy();
          }
        };

        return (
          <div
            css={css`
              ${tw`relative flex justify-between items-center rounded bg-white font-light`};
              padding: 9px 16px;
              border: 1px solid #d8d8d8;
              height: 40px;
            `}
          >
            {smartTruncate(content, maxLength, { position: 10 })}
            <div
              role="button"
              onClick={copyOnClick}
              tabIndex={0}
              onKeyDown={copyOnKeyPress}
              className={['pl-4']}
            >
              <MdContentCopy style={{ fill: '#787777' }} />
              {copied && (
                <div
                  css={css`
                    ${tw`bg-black text-white absolute font-medium`};
                    top: -25px;
                    right: -10px;
                    font-size: 12px;
                    padding: 4px 5px;

                    &:after {
                      border: 5px solid transparent;
                      display: block;
                      border-top-color: #000;
                      content: '';
                      position: absolute;
                      width: 0;
                      height: 0;
                      bottom: -10px;
                      left: 50%;
                      transform: translateX(-50%);
                    }
                  `}
                  role="tooltip"
                  aria-describedby="copied ETH address!"
                >
                  Copied
                </div>
              )}
            </div>
          </div>
        );
      }}
    </CopyToClipboard>
  );
});

ClipboardPane.defaultProps = {
  maxLength: 9999,
};

ClipboardPane.propTypes = {
  content: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

export default ClipboardPane;
