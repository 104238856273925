import React from 'react';
import { ReactComponent as SvgLogo } from '@/components/svg/logo.svg';
import { Button } from '@/shared/styled';
import { css } from 'emotion';

const InstallMetamask = () => {
  const handleInstall = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
      '_blank',
    );
  };

  return (
    <>
      <div
        className="h-screen h-full bg-grey-lighter"
        css={css`
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
        `}
      >
        <header className={['w-screen flex-none bg-white shadow h-16']}>
          <div className="px-6 h-full flex items-center justify-between">
            <span className="inline-flex focus:outline-none">
              <SvgLogo />
            </span>
          </div>
        </header>
      </div>
      <div
        css={css`
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 2;
          background: hsla(0, 0%, 0%, 0.33);
        `}
      >
        <div
          className={[
            'relative bg-white py-8 text-center font-normal rounded font-thin',
          ]}
          css={css`
            width: 400px;
            transform: translate(-50%, -50%);
            top: 45%;
            left: 50%;
          `}
        >
          <div className="mb-6 font-normal text-black">
            <p className="mb-2">Hi friend, to use Dwarves Investment,</p>
            <p className="mb-6">install Metamask on your browser</p>
            <a
              className="text-sm text-pink"
              href="https://metamask.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is Metamask anyway?
            </a>
          </div>
          <div className="px-10 flex justify-between">
            <Button type="primary" onClick={handleInstall}>
              Install
            </Button>
            <Button>Cancel</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallMetamask;
