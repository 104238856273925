import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { css } from 'emotion';

const Container = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`flex justify-end items-center`}
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const Button = function ({ children, disabled, ...rest }) {
  return (
    <div
      css={css`
        ${tw`transparent flex justify-center items-center text-center`};
        width: 50px;
        margin-top: 2px;
        border: none;
        height: 32px;
        font-size: 22px;
        cursor: pointer;
        ${disabled ? 'pointer-events: none; opacity: 0.5' : ''}
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const InfoWrapper = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`flex items-center text-center`};
        input {
          ${tw`text-center border border-grey`}
          width: 80px;
          fontsize: 16px;
          height: 32px;
          margin: 0 3px;
        }
      `}
      {...rest}
    >
      {' '}
      {children}
    </div>
  );
};

export default function Pagination({ current, onChange, max }) {
  const [value, setValue] = useState(current);

  const goPrev = () => {
    let prevValue = parseInt(value) - 1;
    if (value > 1) {
      onChange(prevValue);
      setValue(prevValue);
    }
  };

  const goNext = () => {
    let nextValue = parseInt(value) + 1;
    if (value < max) {
      onChange(nextValue);
      setValue(nextValue);
    }
  };

  const handleChange = (e) => {
    const key = e.key;
    const targetValue = e.target.value;
    let nextValue = targetValue;
    if (key === 'Enter') {
      onChange(parseInt(nextValue));
    } else if (key === ' ') {
      e.preventDefault();
    }
    if (/^[0-1]([0-9]|0[1-9][0-9])$/g.test(targetValue)) {
      nextValue =
        parseInt((targetValue + '').slice(1)) === 0
          ? 1
          : parseInt((targetValue + '').slice(1));
    } else if (targetValue > max) {
      nextValue = max;
    } else if (targetValue === '') {
      nextValue = 1;
    } else if (isNaN(targetValue)) {
      nextValue = value;
    }

    setValue(parseInt(value));
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      <Button onClick={goPrev} disabled={value === 1}>
        <MdChevronLeft />
      </Button>

      <InfoWrapper>
        <span className="text-sm font-medium text-grey-darker mr-2">Page:</span>
        <input
          type="text"
          className="input w-20 text-center text-sm"
          value={value}
          onPaste={handlePaste}
          onKeyDown={handleChange}
          onChange={handleChange}
        />
        <span className="text-sm font-medium text-grey-darker ml-2">
          / {max}
        </span>
      </InfoWrapper>
      <Button onClick={goNext} disabled={value === max}>
        <MdChevronRight />
      </Button>
    </Container>
  );
}

Pagination.propTypes = {
  max: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};
