import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import { css } from 'emotion';

const Container = function ({ children, noCenter, ...rest }) {
  return (
    <div
      css={css`
        ${tw`bg-white`};
        padding: 40px;
        overflow: hidden;
        min-width: 400px;
        ${noCenter
          ? ''
          : `position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%;`};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export default function TableSkeletonScreen() {
  return (
    <Container>
      <div className="mb-5">
        <Skeleton width="120px" height="28px" />
      </div>
      <div className="mb-1">
        <Skeleton width="70px" height="14px" />
      </div>
      <div className="mb-3">
        <Skeleton width="100%" height="32px" />
      </div>
      <div className="mb-1">
        <Skeleton width="70px" height="14px" />
      </div>
      <div className="mb-3">
        <Skeleton width="100%" height="32px" />
      </div>
      <div className="mb-1">
        <Skeleton width="70px" height="14px" />
      </div>
      <div className="mb-3">
        <Skeleton width="100%" height="32px" />
      </div>
      <div className="mb-1">
        <Skeleton width="70px" height="14px" />
      </div>
      <div className="mb-1">
        <Skeleton width="100%" height="32px" />
      </div>
    </Container>
  );
}
