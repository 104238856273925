import { isAddress } from 'ethereum-address';

export function isInstalledMetaMask() {
  return !!window.ethereum && window.ethereum.isMetaMask;
}

export function isEthereumAddress(address) {
  return isAddress(address);
}

export function isEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}
