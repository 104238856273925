import React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import { Link } from '@reach/router';
import { Button } from '@/shared/styled';
import { ReactComponent as SvgLogo } from '@/components/svg/logo.svg';
import { Location } from '@reach/router';
import { ROUTES } from '@/constant/routes';
import { css } from 'emotion';

const Menu = function ({ children, ...rest }) {
  return (
    <ul
      css={css`
        ${tw`list-reset`};
        li {
          display: inline-block;
          margin-right: 30px;
          font-weight: 500;
        }

        a {
          ${tw`text-black hover:text-primary`}
        }

        a.active {
          ${tw`pointer-events-none text-primary`}
        }
      `}
    >
      {children}
    </ul>
  );
};

const menu = {
  admin: [
    {
      path: ROUTES.DASHBOARD,
      text: 'Profile',
    },
    {
      path: ROUTES.TRANSACTION,
      text: 'Transactions',
    },
    {
      path: ROUTES.REISSUES,
      text: 'Reissues',
    },
    {
      path: ROUTES.MEMBERS,
      text: 'Members',
    },
    {
      path: ROUTES.INVITE_MEMBER,
      text: 'Invite',
    },
    {
      path: ROUTES.VALUATION,
      text: 'Valuation',
    },
  ],
  user: [
    {
      path: ROUTES.TRANSACTION,
      text: 'Transaction',
    },
  ],
};

export default function GlobalHeader() {
  const {
    logOut,
    user: { is_admin },
  } = useAuthContext();

  const menus = is_admin ? menu.admin : menu.user;

  return (
    <header className={['w-screen flex-none bg-white shadow h-16']}>
      <div className="px-10 h-full flex items-center">
        <span className="inline-flex focus:outline-none">
          <SvgLogo />
        </span>
        <div className="w-full flex justify-between items-center pl-10">
          <Location>
            {({ location }) => {
              const { pathname } = location;
              return (
                <nav>
                  <Menu>
                    {(menus || []).map(({ path, text }) => (
                      <li key={path}>
                        <Link
                          to={path}
                          className={path === pathname ? 'active' : ''}
                        >
                          {text}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <a href="https://bridge.d.foundation" target="_blank" rel="noopener noreferrer">Bridge</a>
                    </li>                    
                  </Menu>
                </nav>
              );
            }}
          </Location>
          <Button
            css={css`
              ${tw`border border-grey-dark`}
            `}
            // className="border border-grey-dark"
            onClick={() => logOut()}
          >
            Log Out
          </Button>
        </div>
      </div>
    </header>
  );
}
