import React from 'react';
import PropTypes from 'prop-types';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { css } from 'emotion';

const Button = function (props) {
  const { active, up, children, ...rest } = props;
  return (
    <button
      css={css`
        display: block;
        width: 30px;
        height: 24px;
        position: relative;
        font-size: 16px;
        outline: none !important;
        border: none;
        cursor: pointer;

        > * {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0.5;
        }

        > *:first-child {
          top: 0;
        }

        > *:last-child {
          bottom: 0;
        }

        ${active && up
          ? `
    > *:first-child {
      top: -4px;
      font-size: 20px;
      opacity: 1;
    }

    `
          : ''}
        ${active && !up
          ? `
    > *:last-child {
      font-size: 20px;
      opacity: 1;
      bottom: -4px;
    }
    `
          : ''}
      `}
      {...rest}
    >
      {children}
    </button>
  );
};

export default function SortArrow({ active, up, ...rest }) {
  return (
    <Button active={active} up={up} {...rest}>
      <MdExpandLess />
      <MdExpandMore />
    </Button>
  );
}

SortArrow.propTypes = {
  active: PropTypes.bool.isRequired,
  up: PropTypes.bool.isRequired,
};
