import React, { useRef } from 'react';
import useSetState from '@/utils/useSetState';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import queryString from 'query-string';
import { Redirect } from '@reach/router';
import PublicFormLayout from '@/components/PublicFormLayout';
import {
  Button,
  InputLabel,
  Heading,
  Container,
  ErrorBlock,
  SuccessBlock,
  SubmitFooter,
} from '@/shared/styled';
import request from '@/utils/request';
import { isEmail, isEthereumAddress } from '@/utils/validate';

export default function Register({ location }) {
  const { current: params } = useRef(queryString.parse(location.search));
  const [state, setState] = useSetState({
    errorMessage: '',
    successMesssage: '',
    submitting: false,
  });

  const validates = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.identity_number) {
      errors.identity_number = 'Required';
    }

    if (!values.full_name) {
      errors.full_name = 'Required';
    }

    if (!values.eth_address) {
      errors.eth_address = 'Required';
    } else if (!isEthereumAddress(values.eth_address)) {
      errors.eth_address = 'Invalid ethereum address';
    }

    return errors;
  };

  const submit = (values, { setSubmitting }) => {
    setState({
      errorMessage: '',
      submitting: true,
    });

    request
      .post('/register', {
        body: JSON.stringify({ ...values, code: params.code }),
      })
      .then((data) => {
        setState({ successMesssage: 'Successfully sent.', submitting: false });

        setTimeout(() => {
          setState({ successMesssage: '' });
        }, 1500);
      })
      .catch((err) => {
        const errorMessage = err.message || 'Cannot submit data';
        setState({
          errorMessage,
          submitting: false,
        });
      });
  };

  if (!params || !params.code) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <PublicFormLayout>
      <Container noCenter>
        <Heading>Register</Heading>

        <Formik
          initialValues={{
            full_name: '',
            email: params.email || '',
            identity_number: '',
            eth_address: '',
          }}
          validate={validates}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputLabel>
                <label htmlFor="full_name">Name:</label>
                <Field type="text" name="full_name" id="full_name" />
                <ErrorMessage name="full_name" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="identity_number">Identity Number:</label>
                <Field type="text" name="identity_number" />
                <ErrorMessage name="identity_number" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="email">Email:</label>
                <Field type="email" name="email" id="email" />
                <ErrorMessage name="email" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="eth_address">ETH Address:</label>
                <Field type="text" name="eth_address" id="eth_address" />
                <ErrorMessage name="eth_address" component="p" />
              </InputLabel>

              <SubmitFooter>
                <Button type="submit" disabled={state.submitting} block>
                  Submit
                </Button>
              </SubmitFooter>
              {state.errorMessage && (
                <ErrorBlock>{state.errorMessage}</ErrorBlock>
              )}
              {state.successMesssage && (
                <SuccessBlock>{state.successMesssage}</SuccessBlock>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </PublicFormLayout>
  );
}
