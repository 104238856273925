import React from 'react';

export default function NotFoundPage() {
  return (
    <div>
      <h1>500</h1>
      <p>Something wrong is happening to your server</p>
    </div>
  );
}
