import React from 'react';
import { InputLabel, Container as ProfileContainer } from '@/shared/styled';
import ClipboardPane from '@/components/ClipboardPane';
import { useAuthContext } from '@/context/AuthContext';
import { css } from 'emotion';

const InputValue = function ({ children, ...rest }) {
  return (
    <span
      css={css`
        ${tw`text-grey-dark font-light`}
      `}
      {...rest}
    >
      {children}
    </span>
  );
};

export default function AdminProfile() {
  const { user } = useAuthContext();
  const {
    full_name,
    eth_address: { address },
    balance,
    identity_number,
    email,
    shares,
    org_supply,
    company_value,
    // liquidity,
  } = user;

  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 520px;
      `}
    >
      <h2
        css={css`
          ${tw`text-2xl font-semibold mb-6`}
        `}
      >
        Profile
      </h2>
      <ProfileContainer noCenter style={{ width: 560 }}>
        <InputLabel inline noMarginTop>
          <label>Name</label>
          <InputValue>{full_name}</InputValue>
        </InputLabel>

        <InputLabel inline>
          <label>Identity Number</label>
          <InputValue>{identity_number}</InputValue>
        </InputLabel>

        <InputLabel inline>
          <label>Email</label>
          <InputValue>{email}</InputValue>
        </InputLabel>

        <InputLabel inline>
          <label>Address</label>
          <ClipboardPane content={address} maxLength={28} />
        </InputLabel>

        <InputLabel inline>
          <label>Share</label>
          <InputValue>{shares + ' %'}</InputValue>
        </InputLabel>

        <InputLabel inline>
          <label>Balance</label>
          <InputValue>
            {balance.toLocaleString({ style: 'currency' }) + ' DFG'}
          </InputValue>
        </InputLabel>
        <InputLabel inline>
          <label>Supply</label>
          <InputValue>
            {org_supply.toLocaleString({ style: 'currency' }) + ' DFG'}
          </InputValue>
        </InputLabel>
        <InputLabel inline>
          <label>Company value</label>
          <InputValue>
            {company_value.toLocaleString({ style: 'currency' }) + ' USD'}
          </InputValue>
        </InputLabel>
      </ProfileContainer>
    </div>
  );
}
