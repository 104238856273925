import React, { useEffect } from 'react';
import LoadSwitcher from '@/components/LoadSwitcher';
import { Heading } from '@/shared/styled';
import useSetState from '@/utils/useSetState';
import request from '@/utils/request';
import { Redirect, useLocation } from '@reach/router';
import CardCollapsible, { CardItem, CardCompanyValue } from './CardCollapsible';
import { css } from 'emotion';
import qs from 'query-string';
import './styles.css';

export default function Valuation(props) {
  const [state, setState] = useSetState({
    fetching: true,
    data: [],
    fetchError: false,
  });
  const { search } = useLocation();
  const { year: yearParam } = qs.parse(search);
  let params = '';
  if (!isNaN(yearParam)) {
    params = `?year=${parseInt(yearParam) + 1}`;
  }

  useEffect(() => {
    setState({ fetching: true });
    request
      .get(`/company${params}`)
      .then((data) => {
        setState({ fetching: false, data: data });
      })
      .catch((err) => {
        setState({ fetchError: true, fetching: false });
      });
  }, [setState, params]);

  if (state.fetchError) {
    return <Redirect to="/404" noThrow />;
  }


  return (
    <LoadSwitcher isLoading={state.fetching && state.data.length === 0}>
      <Heading>Valuation</Heading>
      <div
        css={css`
          ${tw`mb-10`}
        `}
      >
        <CardCollapsible sectionTitle="Company value">
          <CardCompanyValue data={state.data} year={yearParam} />
        </CardCollapsible>
      </div>
      <div
        css={css`
          ${tw`w-full flex justify-between`}
        `}
      >
        <div
          css={css`
            margin-right: 20px;
            ${tw`w-full`}
          `}
        >
          <CardCollapsible sectionTitle="Assets">
            <CardItem year={yearParam} data={state.data} name="assets" />
          </CardCollapsible>
          <CardCollapsible sectionTitle="Taxable Assets">
            <CardItem
              year={yearParam}
              data={state.data}
              name="taxable_assets"
            />
          </CardCollapsible>
          <CardCollapsible sectionTitle="Future Profits">
            <CardItem
              year={yearParam}
              data={state.data}
              name="future_profits"
            />
          </CardCollapsible>
        </div>
        <div
          css={css`
            margin-left: 20px;
            ${tw`w-full`}
          `}
        >
          <CardCollapsible sectionTitle="Historical Net Margin">
            <CardItem
              year={yearParam}
              data={state.data}
              name="historical_net_margin"
              currency="%"
            />
          </CardCollapsible>
          <CardCollapsible sectionTitle="Valuation Pamameters">
            <CardItem
              year={yearParam}
              data={state.data}
              name="valuation_pamameters"
              currency="none"
            />
          </CardCollapsible>
          <CardCollapsible sectionTitle="Liabilities">
            <CardItem year={yearParam} data={state.data} name="liabilities" />
          </CardCollapsible>
        </div>
      </div>
    </LoadSwitcher>
  );
}
