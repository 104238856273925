import React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import { Redirect } from '@reach/router';

export default function Auth({ children }) {
  const { isLogin } = useAuthContext();

  if (isLogin) {
    return <Redirect to="/" noThrow />;
  }

  return children;
}
