import React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import { Redirect } from '@reach/router';

export default function PageRoleSwitcher({ component, ...rest }) {
  const {
    user: { is_admin },
  } = useAuthContext();
  const role = is_admin ? 'admin' : 'user';

  const Page = component[role];

  if (Page) {
    return <Page {...rest} />;
  }

  return <Redirect to="/403" noThrow />;
}
