import React from 'react';
import useSetState from '@/utils/useSetState';
import PageRoleSwitcher from '@/components/PageRoleSwitcher';
import { isEmail } from '@/utils/validate';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import {
  Button,
  InputLabel,
  Heading,
  Container,
  SuccessBlock,
  ErrorBlock,
  SubmitFooter,
} from '@/shared/styled';
import request from '@/utils/request';
import { css } from 'emotion';

const FieldErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <p>{error}</p> : null;
    }}
  />
);

export const InputWrapper = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`flex items-center `}

        button {
          ${tw`flex items-center justify-center bg-grey-lighter font-medium border border-grey rounded-full hover:bg-grey text-black`}
          line-height:18px;
          height: 30px;
          font-size: 20px;
          flex: 30px 0 0;
          cursor: pointer;
          &:first-of-type {
            margin-left: 10px;
            margin-right: 5px;
          }
          &:disabled {
            ${tw`opacity-50 bg-grey-lighter`}
            cursor: not-allowed,
          }
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

function InviteMembers() {
  const [state, setState] = useSetState({
    errorMessage: '',
    successMessage: '',
    submitting: false,
  });

  const validates = (values) => {
    let errors = {};

    errors.emails = [];
    values.emails.forEach((email, index) => {
      if (!email) {
        errors.emails[index] = 'Required';
      } else if (!isEmail(email)) {
        errors.emails[index] = 'Invalid email address';
      }
    });
    if (!errors.emails.length) {
      delete errors.emails;
    }
    return errors;
  };

  const submit = (values) => {
    setState({ errorMessage: '', submitting: true });
    const body = values.emails.filter((email) => isEmail(email));
    request
      .post('/invite', { body: JSON.stringify(body) })
      .then(() => {
        setState({
          successMessage: 'Successfully sent the invitations',
          submitting: false,
        });
        setTimeout(() => {
          setState({ successMessage: '' });
        }, 2000);
      })
      .catch((err) => {
        const errorMessage = err.message || 'Cannot submit data';
        setState({
          errorMessage,
          submitting: false,
        });
      });
  };

  return (
    <Container>
      <Heading>Invite members</Heading>
      <Formik
        initialValues={{ emails: [''] }}
        onSubmit={submit}
        validate={validates}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="emails"
              render={(arrayHelpers) => (
                <div>
                  {values.emails.map((friend, index) => (
                    <InputLabel key={index}>
                      <InputWrapper>
                        <Field
                          name={`emails.${index}`}
                          placeholder="email@domain.com"
                        />
                        <button
                          type="button"
                          className="focus:outline-none"
                          disabled={values.emails.length < 2}
                          onClick={() => {
                            if (values.emails.length > 1) {
                              arrayHelpers.remove(index);
                            }
                          }}
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="focus:outline-none"
                          onClick={() => arrayHelpers.insert(index + 1, '')}
                        >
                          +
                        </button>
                      </InputWrapper>
                      <FieldErrorMessage name={`emails[${index}]`} />
                    </InputLabel>
                  ))}
                  <SubmitFooter>
                    <Button
                      htmlType="submit"
                      type="primary"
                      block
                      disabled={state.submitting}
                    >
                      Send Invitation
                    </Button>
                  </SubmitFooter>
                  {state.errorMessage && (
                    <ErrorBlock>{state.errorMessage}</ErrorBlock>
                  )}
                  {state.successMessage && (
                    <SuccessBlock>{state.successMessage}</SuccessBlock>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      />
    </Container>
  );
}

export default () => <PageRoleSwitcher component={{ admin: InviteMembers }} />;
