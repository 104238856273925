import React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import useSetState from '@/utils/useSetState';
import { LoaderBar } from './legacy/styled';
import InstallMetamask from './InstallMetamask';
import LoginNewUI from './Login';
import Dialog from '@/components/Dialog';
import { Button } from '@/shared/styled';
import { css } from 'emotion';

const Login = (props) => {
  const [state, setState] = useSetState({ error: null, loading: false });
  const { logIn, installedMetamask } = useAuthContext();

  const login = (e) => {
    setState({ error: null, loading: true });
    //just for showing the existence of loading effect
    setTimeout(() => {
      logIn()
        .then(() => {
          // should do nothing here since redirect to / root
        })
        .catch((err) => {
          setState({ error: err.message, loading: false });
        });
    }, 500);
  };

  if (state.loading) {
    return (
      <LoaderBar>
        <div />
      </LoaderBar>
    );
  }
  if (!installedMetamask) {
    return <InstallMetamask />;
  }

  return (
    <>
      <LoginNewUI
        login={login}
        loading={state.loading}
        setErrorDialog={(error) => {
          setState({ error, loading: false });
        }}
      />
      <ErrorDialog
        isOpen={Boolean(state.error)}
        onDismiss={() => {
          setState({ error: null, loading: false });
        }}
        error={state.error}
      />
    </>
  );
};

export default Login;

const ErrorDialog = ({ isOpen, onDismiss, error }) => (
  <Dialog isOpen={isOpen} onDismiss={onDismiss}>
    <div
      css={css`
        ${tw`bg-white py-4 text-center rounded`};
        max-width: 420px;
      `}
    >
      <p className="p-4">{error}</p>
      <div className="pt-2 pb-4">
        <Button
          onClick={() => {
            onDismiss();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
);
