import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Router } from '@reach/router';
import Auth from '@/pages/Auth';
// import PublicReissue from '@/pages/PublicReissue';
import Login from '@/pages/Login';
import Dashboard from '@/pages/Dashboard';
import DashboardHome from '@/pages/DashboardHome';
import Transaction from '@/pages/Transaction';
import Members from '@/pages/Members';
import ReissueList from '@/pages/ReissueList';
import InviteMembers from '@/pages/InviteMembers';
import Register from '@/pages/Register';
import Reissue from '@/pages/Reissue';
import NoAuthenPage from '@/pages/403';
import NotFoundPage from '@/pages/404';
import ServerErrorPage from '@/pages/500';
import ErrorBoundary from '@/components/ErrorBoundary';
import { AuthContextProvider } from '@/context/AuthContext';
import { ROUTES } from './constant/routes';
import Valuation from '@/pages/Valuation';

function App() {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <Router>
          <Auth path={ROUTES.AUTH}>
            <Login path={ROUTES.LOGIN} />
          </Auth>
          {/* <PublicReissue path={ROUTES.PUBLIC_REISSUE} /> */}
          <Register path={ROUTES.REGISTER} />
          <Dashboard path={ROUTES.DASHBOARD}>
            <DashboardHome path={ROUTES.DASHBOARD} />
            <Transaction path={ROUTES.TRANSACTION} />
            <Members path={ROUTES.MEMBERS} />
            <InviteMembers path={ROUTES.INVITE_MEMBER} />
            <ReissueList path={ROUTES.REISSUES} />
            <Reissue path={ROUTES.REISSUE} />
            <Valuation path={ROUTES.VALUATION} />
            <NoAuthenPage path={ROUTES.NO_AUTHEN} />
            <ServerErrorPage path={ROUTES.SERVER_ERROR} />
            <NotFoundPage path="/*" />
          </Dashboard>
        </Router>
      </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default hot(App);
