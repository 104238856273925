import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import './styles.css';

const Dialog = ({ children, onDismiss, isOpen }) => {
  return (
    <>
      <DialogOverlay isOpen={isOpen} onDismiss={() => onDismiss()}>
        <DialogContent
          className={['flex justify-center items-center']}
          style={{ marginTop: '40vh' }}
        >
          {children}
        </DialogContent>
      </DialogOverlay>
    </>
  );
};

export default Dialog;
