import React from 'react';
import { css } from 'emotion';

export const Button = function (props) {
  const {
    children,
    size,
    disabled,
    block,
    className = '',
    htmlType,
    type,
    ...rest
  } = props;
  let btnTypeColor = 'bg-white text-grey-dark border border-grey';
  switch (type) {
    case 'primary': {
      btnTypeColor = 'bg-primary text-white';
      break;
    }
    default: {
    }
  }
  let sizeStyle = css`
    min-width: 128px;
    ${tw`h-10 px-6`};
  `;

  switch (size) {
    case 'small': {
      sizeStyle = css`
        min-width: 84px;
        ${tw`h-8 px-4 text-sm`};
      `;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <button
      type={htmlType}
      css={css`
        ${tw`leading-none cursor-pointer rounded focus:outline-none`}
        ${disabled ? 'opacity: 0.7; pointer-events: none;' : ''};
        width: ${block ? '100%' : 'auto'};
      `}
      className={[btnTypeColor, sizeStyle, className]}
      {...rest}
    >
      {children}
    </button>
  );
};

export const Heading = function (props) {
  const { children, ...rest } = props;

  return (
    <h2
      css={css`
        ${tw`mb-6 text-2xl font-semibold`}
      `}
      {...rest}
    >
      {children}
    </h2>
  );
};

export const InputLabel = function (props) {
  const { flex, inline, children, noMarginTop, ...rest } = props;
  return (
    <div
      css={css`
        margin-bottom: 15px;
        label {
          display: block;
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: 500;
          margin-right: ${flex ? '10px' : '0'};
        }
        input {
          ${tw`border border-grey w-full`};
          height: 36px;
          padding: 0 7px;
          outline: none;
          &[readOnly] {
            ${tw`bg-grey-lighter`}
            cursor: not-allowed;
          }
        }
        p {
          ${tw`text-primary`};
          font-size: 14px;
          margin-top: 5px;
        }
        label {
          width: 130px;
          text-align: right;
          font-size: 16px;
        }
        ${inline
          ? `
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 0;

      label {
        margin-bottom: 0;
        margin-right: 10px;
        opacity: 0.5;
      }`
          : ''}
        ${noMarginTop ? `margin-top: 0px;` : ''}
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export const Container = function ({ children, noCenter, ...rest }) {
  return (
    <div
      css={css`
        ${tw`p-10 overflow-hidden bg-white border border-grey-lighter rounded`};
        min-width: 400px;
        ${noCenter
          ? ''
          : `position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%;`};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export const SubmitFooter = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        margin-top: 35px;
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export const ErrorBlock = function ({ children, ...rest }) {
  return (
    <p
      css={css`
        ${tw`mt-4 py-2 px-3 leading-none bg-red-lighter text-sm text-red rounded`}
      `}
      {...rest}
    >
      {children}
    </p>
  );
};

export const SuccessBlock = function ({ children, ...rest }) {
  return (
    <p
      css={css`
        ${tw`mt-4 py-2 leading-none px-3 bg-green-lighter text-sm rounded text-green`}
      `}
      {...rest}
    >
      {children}
    </p>
  );
};
