import React from 'react';
import GlobalHeader from '@/components/GlobalHeader';
import { css } from 'emotion';

export default function DefaultLayout({ children }) {
  return (
    <>
      <GlobalHeader />
      <main
        css={css`
          min-width: 960px;
          margin: 40px 20px;
          padding: 0 20px;
        `}
      >
        {children}
      </main>
    </>
  );
}
