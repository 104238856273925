import deepmerge from 'deepmerge';

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function request(url, options, statusCheckCallback = checkStatus) {
  return fetch(url, options).then(statusCheckCallback).then(parseJSON);
}

export default (function () {
  const baseUri =
    process.env.REACT_APP_CORE_API || 'https://be.ir.d.foundation';
  const baseOptions = {
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  let statusCheckCallback = checkStatus;
  const getStatusCheckCallback = () => statusCheckCallback;

  const token = localStorage.getItem('token');
  if (token) {
    baseOptions['headers']['Authorization'] = 'Bearer ' + token;
  }

  return {
    get: (path, options = {}) =>
      request(
        baseUri + path,
        {
          ...deepmerge(baseOptions, options),
          method: 'GET',
        },
        getStatusCheckCallback(),
      ),
    post: (path, options) =>
      request(
        baseUri + path,
        {
          ...deepmerge(baseOptions, options),
          method: 'POST',
        },
        getStatusCheckCallback(),
      ),
    put: (path, options) =>
      request(
        baseUri + path,
        {
          ...deepmerge(baseOptions, options),
          method: 'PUT',
        },
        getStatusCheckCallback(),
      ),
    delete: (path, options) =>
      request(
        baseUri + path,
        {
          ...deepmerge(baseOptions, options),
          method: 'delete',
        },
        getStatusCheckCallback(),
      ),
    setAuthToken: (token) => {
      baseOptions['headers']['Authorization'] = 'Bearer ' + token;
    },
    removeAuthToken: () => {
      delete baseOptions['Authorization'];
    },
    setStatusCheckCallback: (callback) => {
      statusCheckCallback = callback;
    },
  };
})();
