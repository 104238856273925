import React from 'react';

export default function NoAuthenPage() {
  return (
    <div>
      <h1>403</h1>
      <p>You have no permission to go further.</p>
    </div>
  );
}
