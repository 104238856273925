import React, { useEffect } from 'react';
import useSetState from '@/utils/useSetState';
import PageRoleSwitcher from '@/components/PageRoleSwitcher';
import FlexTable from '@/components/FlexTable';
import { MdCheck, MdClose } from 'react-icons/md';
import request from '@/utils/request';
import LoadSwitcher from '@/components/LoadSwitcher';
import { Redirect } from '@reach/router';
import ClipboardPane from '@/components/ClipboardPane';
import { Link } from '@reach/router';
import { ROUTES } from '@/constant/routes';
import { css } from 'emotion';

const NameColumn = ({ full_name }) => <div> {full_name} </div>;

const IsFortressMemberColumn = ({ is_fortress_member }) => (
  <div style={{ fontSize: '18px' }}>
    {' '}
    {is_fortress_member ? (
      <span
        css={css`
          ${tw`text-green`}
        `}
      >
        <MdCheck />
      </span>
    ) : (
      <span
        css={css`
          ${tw`text-red`}
        `}
      >
        <MdClose />
      </span>
    )}
  </div>
);

const IDColumn = ({ identity_number, email }) => (
  <div>
    <div>{identity_number || '---'}</div>
  </div>
);

const EmailColumn = ({ email }) => (
  <div>
    <div>{email || '---'}</div>
  </div>
);

const EthColumn = ({ eth_address: { address } }) =>
  address ? <ClipboardPane maxLength={16} content={address} /> : '---';

const HashColumn = ({ hash }) =>
  hash ? <ClipboardPane maxLength={16} content={hash} /> : '---';

const viewModel = [
  {
    title: 'Name',
    pointTo: 'full_name',
    columnComponent: NameColumn,
    comparable: (a, b) => {
      const aL = /\w*$/g.exec(a.full_name.charAt(0))[0].toLowerCase();
      const bL = /\w*$/g.exec(b.full_name.charAt(0))[0].toLowerCase();
      return aL.localeCompare(bL);
    },
    sorting: true,
  },
  {
    title: 'ID Number',
    pointTo: 'identity_number',
    columnComponent: IDColumn,
  },
  {
    title: 'Email',
    pointTo: 'email',
    columnComponent: EmailColumn,
  },
  {
    title: 'ETH addresss',
    pointTo: 'eth_address',
    columnComponent: EthColumn,
  },
  {
    title: 'Hash',
    pointTo: 'hash',
    columnComponent: HashColumn,
  },
  {
    title: 'Fortress',
    pointTo: 'is_fortress_member',
    columnComponent: IsFortressMemberColumn,
  },
];

const columnDivisionClass = `
  > div:nth-child(1) {
    width: 12%;
  }

  > div:nth-child(2) {
    width: 12%;
  }

  > div:nth-child(3) {
    width: 18%;
  }

  > div:nth-child(4) {
    width: 25%;
  }

  > div:nth-child(5) {
    width: 25%;
  }

  > div:nth-child(6) {
    width: 8%;
  }
`;

function Members() {
  const [state, setState] = useSetState({
    fetching: true,
    data: [],
    fetchError: false,
  });

  useEffect(() => {
    request
      .get('/members?offset=0&limit=25')
      .then((data) => {
        setState({ data, fetching: false });
      })
      .catch((err) => {
        setState({ fetchError: true });
      });
  }, []); // eslint-disable-line

  if (state.fetchError) {
    return <Redirect to="/no-access" noThrow />;
  }

  return (
    <LoadSwitcher isLoading={state.fetching}>
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-2xl font-semibold inline">Members</h2>
        <div>
          <Link
            css={css`
              width: 128px;
              padding: 12px 0;
              ${tw`inline-block text-center bg-primary text-white leading-none rounded font-thin`};
            `}
            to={ROUTES.INVITE_MEMBER}
          >
            Invite
          </Link>
        </div>
      </div>
      <FlexTable
        data={state.data}
        viewModel={viewModel}
        columnDivisionClass={columnDivisionClass}
      />
    </LoadSwitcher>
  );
}

export default () => <PageRoleSwitcher component={{ admin: Members }} />;
