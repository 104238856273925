import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0% {
    left: -100%;
  }
  49% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
`;

export const Container = styled.div`
  ${tw`border bg-white border-grey`};
  padding: 40px;
  position: relative;
  overflow: hidden;
  min-width: 500px;
  border-bottom-width: 2px;
`;

export const AddressHolder = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
    a {
      ${tw`border-b border-b-primary text-primary`};
      fontweight: 500;
      cursor: pointer;
    }
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  a,
  span[role='button'] {
    cursor: pointer;
    text-decoration: none;
    ${tw`text-primary underline`};
  }
`;

const SharedMarker = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  flex: 18px 0 0;
  border-radius: 999px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  margin-right: 10px;
`;

export const CheckMarker = styled(SharedMarker)`
  ${tw`border border-green text-green`};
`;

export const TimesMarker = styled(SharedMarker)`
  ${tw`border border-red text-red`};
`;

export const LoaderBar = styled.div`
  ${tw`w-full overflow-hidden absolute pin-x pin-t`};
  height: 3px;
  div {
    ${tw`w-full bg-primary relative`};
    height: 5px;
    animation: ${loaderAnimation} 3s ease-in-out infinite;
  }
`;
