import React from 'react';
import { absoluteFullwidth, absoluteCenter } from '@/shared/styles';
import Logo from '@/components/svg/Logo';
import { Link } from '@reach/router';
import { css } from 'emotion';

const Container = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`bg-grey-lighter`};
        ${absoluteFullwidth};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const Wrapper = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${absoluteCenter};
        margin-top: -100px;
        max-width: 500px;
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const LogoWrap = function ({ children, ...rest }) {
  return (
    <Link
      css={css`
        ${tw`flex items-center justify-center`};
        margin-bottom: 50px;
        margin-right: 20px;
        svg {
          font-size: 56px;
        }
        span {
          ${tw`uppercase text-black`}
          font-weight: 700;
          font-size: 18px;
          text-transform: uppercase;
          margin-left: 16px;
          line-height: 1.35;
        }
      `}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default function PublicFormLayout({ children }) {
  return (
    <Container>
      <Wrapper>
        <LogoWrap to="/">
          <Logo width="1em" height="1em" />
          <span>
            dwarves
            <br /> foundation
          </span>
        </LogoWrap>
        {children}
      </Wrapper>
    </Container>
  );
}
