import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  InputLabel,
  ErrorBlock,
  SuccessBlock,
  SubmitFooter,
  Button,
} from '@/shared/styled';
import request from '@/utils/request';
import { isEmail, isEthereumAddress } from '@/utils/validate';
import { ReactComponent as GoBackSvg } from './arrow.svg';
import { css } from 'emotion';

const FieldCustom = function ({ children, ...rest }) {
  return (
    <Field
      css={css`
        &&& {
          padding: 0 16px;
          &::placeholder {
            font-weight: 300;
            ${tw`text-grey`}
          }
        }
        ${tw`rounded`}
      `}
      {...rest}
    >
      {children}
    </Field>
  );
};

class Reissue extends Component {
  state = {
    errorMessage: '',
    successMesssage: '',
    submitting: false,
  };

  validates = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.identity_number) {
      errors.identity_number = 'Required';
    }

    if (!values.full_name) {
      errors.full_name = 'Required';
    }

    if (!values.new_eth_address) {
      errors.new_eth_address = 'Required';
    } else if (!isEthereumAddress(values.new_eth_address)) {
      errors.new_eth_address = 'Invalid ethereum address';
    }

    return errors;
  };

  submit = (values, { setSubmitting }) => {
    this.setState({
      errorMessage: '',
      successMesssage: '',
      submitting: true,
    });
    request
      .post('/reissues', { body: JSON.stringify(values) })
      .then((res) => {
        this.setState(
          { successMesssage: 'Successfully sent.', submitting: false },
          () => {
            setTimeout(() => {
              this.setState({ successMesssage: '' });
            }, 1500);
          },
        );
      })
      .catch((err) => {
        const { setErrorDialog } = this.props;
        const errorMessage = err.message || 'Cannot submit data';
        this.setState({
          // errorMessage,
          submitting: false,
        });
        setErrorDialog && setErrorDialog(errorMessage);
      });
  };

  handleGoBack = () => {
    const { goBackLogin } = this.props;
    goBackLogin && goBackLogin();
  };

  render() {
    return (
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          identity_number: '',
          new_eth_address: '',
        }}
        validate={this.validates}
        onSubmit={this.submit}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputLabel>
              <FieldCustom
                type="text"
                name="full_name"
                id="full_name"
                placeholder="Name"
              />
              <ErrorMessage name="full_name" component="p" />
            </InputLabel>

            <InputLabel>
              <FieldCustom
                type="text"
                name="identity_number"
                placeholder="ID Number"
              />
              <ErrorMessage name="identity_number" component="p" />
            </InputLabel>

            <InputLabel>
              <FieldCustom
                type="email"
                name="email"
                id="email"
                placeholder="Email"
              />
              <ErrorMessage name="email" component="p" />
            </InputLabel>

            <InputLabel>
              <FieldCustom
                type="text"
                name="new_eth_address"
                id="new_eth_address"
                placeholder="New ETH"
              />
              <ErrorMessage name="new_eth_address" component="p" />
            </InputLabel>

            <SubmitFooter>
              <Button
                htmlType="submit"
                type="primary"
                disabled={this.state.submitting}
                block
              >
                Submit
              </Button>
            </SubmitFooter>
            {this.state.errorMessage && (
              <ErrorBlock>{this.state.errorMessage}</ErrorBlock>
            )}
            {this.state.successMesssage && (
              <SuccessBlock>{this.state.successMesssage}</SuccessBlock>
            )}
            <div className="mt-4">
              <span
                className="inline-flex items-center text-pink cursor-pointer"
                onClick={this.handleGoBack}
              >
                <GoBackSvg className="inline" />
                {'\u00A0'}
                {'\u00A0'}
                {'Back to Sign in'}
              </span>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Reissue;
