import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import { css } from 'emotion';

const Header = function ({ children, ...rest }) {
  return (
    <header
      css={css`
        ${tw`bg-white`}
      `}
      {...rest}
    >
      {children}
    </header>
  );
};

const Container = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`items-center flex justify-between`}
        max-width: 1140px;
        min-width: 960px;
        padding: 0 20px;
        height: 80px;
        margin: auto;
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const Logo = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`items-center flex`}
        > div {
          margin-right: 15px;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const Menu = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        ${tw`items-center flex`}
        > div {
          margin-left: 15px;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

const MidBlock = function ({ children, ...rest }) {
  return (
    <div
      css={css`
        min-height: calc(100vh - 80px);
        ${tw`items-center flex justify-center`};

        > div {
          padding: 40px 35px;
          max-width: 400px;
          width: 100%;
          ${tw`bg-white`}

          > div:not(:last-of-type) {
            margin-bottom: 10px;
          }

          > div:first-of-type {
            margin-bottom: 20px;
          }
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export default function DashboardSkeleton() {
  return (
    <div>
      <Header>
        <Container>
          <Logo>
            <Skeleton width="36px" height="36px" />
            <Skeleton width="80px" height="18px" />
          </Logo>
          <Menu>
            <Skeleton width="80px" height="14px" />
            <Skeleton width="45px" height="14px" />
            <Skeleton width="55px" height="14px" />
          </Menu>
        </Container>
      </Header>

      <MidBlock>
        <div>
          <Skeleton width="100px" height="28px" />
          <br />
          <Skeleton width="80%" height="14px" />
          <br />
          <Skeleton width="90%" height="14px" />
          <br />
          <Skeleton width="50%" height="14px" />
          <br />
          <Skeleton width="60%" height="14px" />
          <br />
          <Skeleton width="80%" height="14px" />
        </div>
      </MidBlock>
    </div>
  );
}
