import React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import { Redirect } from '@reach/router';
import DefaultLayout from '@/components/DefaultLayout';
import DashboardSkeleton from '@/components/skeleton/Dashboard';

export default function Dashboard({ children }) {
  const { isLogin, userLoading } = useAuthContext();

  if (!isLogin) {
    return <Redirect to="/auth/login" noThrow />;
  }

  if (userLoading) {
    return <DashboardSkeleton />;
  }

  return <DefaultLayout>{children}</DefaultLayout>;
}
