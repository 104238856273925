import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useSetState from '@/utils/useSetState';
import LoadSwitcher from '@/components/LoadSwitcher';
import {
  Button,
  InputLabel,
  Heading,
  Container,
  ErrorBlock,
  SuccessBlock,
  SubmitFooter,
} from '@/shared/styled';
import { Redirect } from '@reach/router';
import request from '@/utils/request';
import { isEmail, isEthereumAddress } from '@/utils/validate';
import CenterFormSkeleton from '@/components/skeleton/CenterForm';

function Reissue({ id }) {
  const [state, setState] = useSetState({
    fetching: true,
    errorMessage: '',
    successMesssage: '',
    submitting: false,
    initialValues: {},
    fetchError: false,
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    request
      .get(`/reissues/${id}`)
      .then((data) => {
        setState({ fetching: false, initialValues: data });
      })
      .catch((err) => {
        setState({ fetchError: true });
      });
  }, []); // eslint-disable-line

  if (!id) {
    return <Redirect to="/" noThrow />;
  }

  const validates = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.identity_number) {
      errors.identity_number = 'Required';
    }

    if (!values.full_name) {
      errors.full_name = 'Required';
    }

    if (!values.new_eth_address) {
      errors.new_eth_address = 'Required';
    } else if (!isEthereumAddress(values.new_eth_address)) {
      errors.new_eth_address = 'Invalid ethereum address';
    }

    return errors;
  };

  const submit = (values, { setSubmitting }) => {
    setState({
      errorMessage: '',
      submitting: true,
    });
    request
      .put('/reissues', { body: JSON.stringify({ id: values.id }) })
      .then((res) => {
        setState({ successMesssage: 'Successfully sent.', submitting: false });
        setTimeout(() => {
          setState({ successMesssage: '' });
        }, 1500);
      })
      .catch((err) => {
        const errorMessage = err.message || 'Cannot submit data';
        setState({
          errorMessage,
          submitting: false,
        });
      });
  };

  if (state.fetchError) {
    return <Redirect to="/404" noThrow />;
  }

  return (
    <LoadSwitcher isLoading={state.fetching} fallback={<CenterFormSkeleton />}>
      <Container>
        <Heading>Reissue</Heading>
        <Formik
          initialValues={{ ...state.initialValues }}
          validate={validates}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputLabel>
                <label htmlFor="full_name">Name:</label>
                <Field readOnly type="text" name="full_name" id="full_name" />
                <ErrorMessage name="full_name" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="identity_number">Identity Number:</label>
                <Field readOnly type="text" name="identity_number" />
                <ErrorMessage name="identity_number" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="email">Email:</label>
                <Field readOnly type="email" name="email" id="email" />
                <ErrorMessage name="email" component="p" />
              </InputLabel>

              <InputLabel>
                <label htmlFor="new_eth_address">New ETH:</label>
                <Field
                  readOnly
                  type="text"
                  name="new_eth_address"
                  id="new_eth_address"
                />
                <ErrorMessage name="new_eth_address" component="p" />
              </InputLabel>

              <SubmitFooter>
                <Button type="submit" disabled={state.submitting} block>
                  Submit
                </Button>
              </SubmitFooter>
              {state.errorMessage && (
                <ErrorBlock>{state.errorMessage}</ErrorBlock>
              )}
              {state.successMesssage && (
                <SuccessBlock>{state.successMesssage}</SuccessBlock>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </LoadSwitcher>
  );
}

export default Reissue;
