import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';

export default function TableSkeletonScreen() {
  return (
    <div>
      <Skeleton width="160px" height="30px" />
      <div className="mt-4 py-3 bg-white">
        {[1, 2, 3, 4, 5, 6, 7].map((i) => (
          <div className="flex bg-white py-3 px-3" key={i}>
            <div className="col w-1/5 px-3">
              <Skeleton width="100%" height="16px" />
            </div>
            <div className="col w-2/5 px-3">
              <Skeleton width="100%" height="16px" />
            </div>
            <div className="col w-1/5 px-3">
              <Skeleton width="100%" height="16px" />
            </div>
            <div className="col w-1/5 px-3">
              <Skeleton width="100%" height="16px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
