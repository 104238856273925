import React, { useState, useContext, useEffect } from 'react';
import request from '@/utils/request';
import { isInstalledMetaMask } from '@/utils/validate';

const AuthContext = React.createContext({
  isLogin: false,
  logOut: () => {},
  logIn: () => {},
});

export function AuthContextProvider({ children }) {
  const [isLogin, setIsLogin] = useState(!!localStorage.getItem('token'));
  const [address, setAddress] = useState();
  const [user, setUser] = useState();
  const [userLoading, setUserLoading] = useState(true);
  const [installedMetamask] = useState(isInstalledMetaMask());
  const [isWeb3Login, setIsWeb3Login] = useState(false);

  const logOut = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    setIsLogin(false);
    request
      .post('/logout', {
        body: JSON.stringify({
          eth_address: address,
          token,
        }),
      })
      .catch((err) => {
        console.error(err);
      });
    localStorage.removeItem('token');
    request.removeAuthToken();
  };

  const logIn = () => {
    return new Promise((resolve, reject) => {
      request
        .post('/login', { body: JSON.stringify({ eth_address: address }) })
        .then((res) => {
          const { access_token: token, ...user } = res;
          setUserLoading(false);
          setUser(user);
          localStorage.setItem('token', token);
          request.setAuthToken(token);
          setIsLogin(true);
          resolve('success');
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  request.setStatusCheckCallback(async (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (response.status === 401) {
      logOut();
    }

    const { message } = await response.json();
    const error = new Error(response.statusText);
    error.message = message || 'Fail to fetch';
    throw error;
  });

  const watchChanges = () => {
    const { ethereum } = window;
    if (!ethereum) return;

    ethereum.on('accountsChanged', (accounts) => {
      logOut();
      if (accounts[0]) {
        setAddress(accounts[0]);
        setIsWeb3Login(true);
      } else {
        setAddress('');
        setIsWeb3Login(false);
      }
    });
  };

  useEffect(() => {
    if (installedMetamask) {
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then((err, accounts) => {
          watchChanges();
          if (!err && accounts.length > 0) {
            setIsWeb3Login(true);
            setAddress(accounts[0]);
          } else {
            logOut();
            setIsWeb3Login(false);
            setAddress('');
          }
        });
    } else {
      logOut();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (installedMetamask) {
      return;
    }
    // check for page visibility and reload if MetaMask ext is not installed
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        window.location.reload();
      }
    });
  }, [installedMetamask]);

  useEffect(() => {
    if (!isWeb3Login || !isLogin || !address) {
      return;
    }
    setUserLoading(true);
    request
      .get('/profile')
      .then((user) => {
        if (address === user.eth_address.address) {
          setUser(user);
          setUserLoading(false);
        } else {
          logOut();
        }
      })
      .catch((err) => {
        console.error(err);
        logOut();
      });
  }, [address, isWeb3Login]); // eslint-disable-line

  const web3Login = () => {
    window.ethereum &&
      window.ethereum
        .enable()
        .then((accounts) => {
          if (accounts[0]) {
            setIsWeb3Login(true);
            setAddress(accounts[0]);
          }
        })
        .catch((e) => {
          setIsWeb3Login(false);
          setAddress('');
        });
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        logOut,
        logIn,
        web3Login,
        address,
        isWeb3Login,
        installedMetamask,
        user,
        userLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
