import React, { useEffect } from 'react';
import useSetState from '@/utils/useSetState';
import PageRoleSwitcher from '@/components/PageRoleSwitcher';
import { Heading } from '@/shared/styled';
import FlexTable from '@/components/FlexTable';
import request from '@/utils/request';
import LoadSwitcher from '@/components/LoadSwitcher';
import { Link } from '@reach/router';
import { Redirect } from '@reach/router';
import ClipboardPane from '@/components/ClipboardPane';
import { ROUTES } from '@/constant/routes';
import { css } from 'emotion';

const NameColumn = ({ full_name }) => (
  <div style={{ fontWeight: 500 }}>{full_name}</div>
);
const EmailColumn = ({ email }) => <span>{email || '---'}</span>;
const AddressColumn = ({ new_eth_address }) => (
  <ClipboardPane maxLength={18} content={new_eth_address} />
);
const IdentityColumn = ({ identity_number }) => (
  <span>{identity_number || '---'}</span>
);

const viewModel = [
  {
    title: 'Name',
    pointTo: 'full_name',
    columnComponent: NameColumn,
    comparable: (a, b) => {
      const aL = /\w*$/g.exec(a.full_name.charAt(0))[0].toLowerCase();
      const bL = /\w*$/g.exec(b.full_name.charAt(0))[0].toLowerCase();
      return aL.localeCompare(bL);
    },
    sorting: true,
  },
  {
    title: 'Identity',
    pointTo: 'identity_number',
    columnComponent: IdentityColumn,
    sorting: true,
  },
  {
    title: 'Email',
    pointTo: 'email',
    columnComponent: EmailColumn,
  },
  {
    title: 'ETH Address',
    pointTo: 'new_eth_address',
    columnComponent: AddressColumn,
    sorting: true,
  },
];

const CustomRowTemplate = ({ children, ...rest }) => (
  <Link
    css={css`
      padding: 20px 0;
      color: #111111;
      &:hover: {
        backgroundcolor: #fafafa;
      }
    `}
    to={`${ROUTES.REISSUES}/${rest.id}`}
    {...rest}
  >
    {children}
  </Link>
);

function ReissueList() {
  const [state, setState] = useSetState({
    fetching: true,
    data: [],
    fetchError: false,
  });

  useEffect(() => {
    request
      .get('/reissues')
      .then((data) => {
        setState({ fetching: false, data });
      })
      .catch((err) => {
        setState({ fetchError: true });
      });
  }, [setState]);

  if (state.fetchError) {
    return <Redirect to="/403" noThrow />;
  }

  return (
    <LoadSwitcher isLoading={state.fetching}>
      <Heading>Reissue Requests</Heading>
      <FlexTable
        data={state.data}
        viewModel={viewModel}
        rowWrapTemplate={CustomRowTemplate}
        columnDivisionClass={`
          >div:nth-of-type(1) { width: 28% };
          >div:nth-of-type(2) { width: 17% };
          >div:nth-of-type(3) { width: 30% };
          >div:nth-of-type(4) { width: 25% };
        `}
      />
    </LoadSwitcher>
  );
}

export default () => <PageRoleSwitcher component={{ admin: ReissueList }} />;
